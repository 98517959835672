import * as React from "react"
import { Link } from "gatsby"
import "../../css/_section-audit.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { RecCard } from "../../components/resources"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/resources/",
    label: "Resources",
  },
]

const blogs = [
  {
    hrf: "https://vigilant.sdcdn.app/pdf/client-stories/a-strategic-approach-to-ensuring-compliance-and-security.pdf",
    rec: "../images/client-stories/VigilantMVDR.jpg",
    subHeader: "Success Story",
    title: "VigilantMVDR",
    content:
      "Learn how this aerospace industry manufacturer reduced their attack surface and made progress in their compliance initiatives.",
  },
  {
    hrf: "https://sp1.sdcdn.app/pdf/MDR-Success-Story.pdf",
    rec: "../images/resource/VigilantMDR.jpg",
    subHeader: "Success Story",
    title: "VigilantMDR",
    content:
      "Large manufacturing company protects against data and revenue loss from ransomware and other advanced attacks with Vigilant. ",
  },
  {
    hrf: "/bandwidth/case-study/",
    rec: "../images/resource/band_cardimage.jpg",
    subHeader: "Success Story",
    title: "VigilantMNDR",
    content:
      "A large healthcare company reduced bandwidth troubleshooting from weeks to minutes.",
  },
  {
    hrf: "https://sp1.sdcdn.app/pdf/MEDR-Success-Story.pdf",
    rec: "../images/resource/VigilantMEDR.jpg",
    subHeader: "Success Story",
    title: "VigilantMEDR",
    content:
      "National retail company avoids a potentially expensive and damaging malware attack with Vigilant.",
  },
  {
    hrf: "https://sp1.sdcdn.app/pdf/MNDR-Success-Story.pdf",
    rec: "../images/resource/VigilantMNDR.jpg",
    subHeader: "Success Story",
    title: "VigilantMNDR",
    content:
      "Large packaging supply chain company gets holisitic visibility into their network with Vigilant.",
  },
  {
    hrf: "https://sp1.sdcdn.app/pdf/V365-Success-Story.pdf",
    rec: "../images/resource/Vigilant365.jpg",
    subHeader: "Success Story",
    title: "Vigilant365™",
    content:
      "Mid-size technology company prevents unauthorized access to Microsoft 365 account with Vigilant.",
  },
]
const SuccessStories = () => {
  return (
    <Layout>
      <Seo title="Security Threat Audit" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="grid--bg--for-it-ops-3a">
        <section className="section-title-top">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h1>RESOURCES</h1>
              </div>
              <div className="section-col-2"></div>
            </div>
          </div>
        </section>
        <section className="section-client-cards">
          <div className="container">
            <div className="row pb-5 blog--nav">
              <div className="col-sm-2 ">
                <Link to="/resources/" activeClassName="blog--nav-active">
                  ALL RESOURCES
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/solution-briefs"
                  activeClassName="blog--nav-active"
                >
                  SOLUTION BRIEFS
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/success-stories"
                  activeClassName="blog--nav-active"
                >
                  SUCCESS STORIES
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/battlecards"
                  activeClassName="blog--nav-active"
                >
                  BATTLECARDS
                </Link>
              </div>
            </div>
            <div className="row">
              <ul className="client-cards-lists">
                {blogs.map(blog => {
                  return (
                    <RecCard
                      imgSrc={blog.rec}
                      subHeader={blog.subHeader}
                      title={blog.title}
                      content={blog.content}
                      hrf={blog.hrf}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}
export default SuccessStories
